<template>
  <div class="subject-card-component" v-if="page_data">
    <div class="subject-header">
      <h3>{{ $t("g.subjects") }}</h3>
      <div class="d-flex justify-content-between align-items-center">
        <span>
          <strong>{{
            `${$t("g.count")}: ${page_data.subjects.length}`
          }}</strong>
        </span>
        <b-button
          v-if="permissions.update"
          variant="primary"
          @click="$bvModal.show('bv-modal-add-subject')"
          class="ml-2 add-subject"
        >
          {{ $t("g.edu_year/add_subject") }}
        </b-button>
      </div>
    </div>
    <b-row v-if="page_data">
      <b-col
        v-for="(subject, index) of page_data.subjects"
        :key="subject.id"
        cols="auto"
      >
        <b-card
          :title="subject.name"
          :img-src="
            subject.media && subject.media.length
              ? subject.media[0].path
              : subjectImagePlaceholder
          "
          :img-alt="subject.name"
          img-top
          tag="article"
          class="mb-2"
          body-class="d-flex flex-column justify-content-between align-items-start"
        >
          <b-button
            v-if="permissions.update"
            class="mt-1"
            variant="danger"
            @click="deleteAlert(subject.id, index)"
            >{{ $t("g.delete") }}</b-button
          >
        </b-card>
      </b-col>
    </b-row>
    <h2 v-else class="no-data-msg">
      {{ $t("g.there_is_no_subjects_at_the_time") }}
    </h2>

    <AddSubjectModal
      @get-subjects="handleGetSubjects"
      :id="page_data.id"
      :is_deleted="is_deleted"
    ></AddSubjectModal>
  </div>
</template>

<script>
import OverlayComponent from "@/components/shared/OverlayComponent";
import {
  BRow,
  BCol,
  BLink,
  BCard,
  BButton,
  BCardText,
  BModal,
} from "bootstrap-vue";
import AddSubjectModal from "@/components/EduYear/AddSubjectModal/EduYearAddSubject.vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCard,
    BButton,
    BCardText,
    AddSubjectModal,
    BModal,
    OverlayComponent,
  },
  props: ["page_data", "permissions"],
  data() {
    return {
      is_loading: false,
      plusIcon: require("@/assets/dev/plus.svg"),
      subjectImagePlaceholder: require("@/assets/images/edu-year/subject-holder.jpg"),
      is_deleted: false,
    };
  },
  methods: {
    deleteAlert(id, index) {
      this.$helpers.sweetAlertConfirm(
        this.$t("g.are_you_sure"),
        this.$t("g.you_wont_be_able_to_revert_this"),
        "warning",
        this.$t("g.yes_delete_it"),
        this.$t("g.cancel"),
        () => this.deleteSubject(id, index)
      );
    },
    deleteSubject(id, index) {
      let formData = new FormData();
      formData.append(`subjects[${index}]`, id);
      this.$http
        .post(
          `admin/edu-years/${this.page_data.id}/delete-subjects?_method=put`,
          formData
        )
        .then((res) => {
          this.$emit("get-edu-year");
          this.is_deleted = true;
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          this.$helpers.handleError(err);
          this.isLoading = false;
        });
    },
    handleGetSubjects() {
      this.$emit("get-edu-year");
    },
  },
};
</script>

<style lang="scss">
@import "./_SubjectCardComponent.scss";
</style>
