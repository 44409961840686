<template>
  <content-not-view v-if="subPermission.show == false" />
  <div v-else>
    <EdyYearInfoComponent :page_data="items"></EdyYearInfoComponent>
    <SubjectCardComponent
      @get-edu-year="getEduYear"
      :page_data="items"
      :permissions="subPermission"
    ></SubjectCardComponent>
  </div>
</template>

<script>
import EdyYearInfoComponent from "@/components/EduYear/Info/EdyYearInfoComponent.vue";
import SubjectCardComponent from "@/components/EduYear/SubjectCard/SubjectCardComponent.vue";
import contentNotView from "@/components/contentNotView/contentNotView.vue";

export default {
  components: {
    SubjectCardComponent,
    EdyYearInfoComponent,
    contentNotView,
  },
  data() {
    return {
      current_id: null,
      items: null,
    };
  },
  created() {
    if (this.$route.params.id) {
      this.current_id = this.$route.params.id;
      this.getEduYear();
    }
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSION", "edu-years");
      return this.$store.state.userData.sub_Permission;
    },
  },
  methods: {
    getEduYear() {
      this.$http
        .get(`admin/edu-years/${this.current_id}`)
        .then((res) => {
          this.items = res.data.data;
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          console.log(err);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style></style>
