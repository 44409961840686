<template>
  <div class="edu-year-info" v-if="page_data">
    <b-card :title="$t('g.basicInformations')" class="info">
      <div class="info-box">
        <div class="frame">
          <b-img
          :src="
            page_data.media.length === 0
              ? ImagePlaceholder
              : page_data.media[0].path
          "
        />
        </div>
        <div class="title">
          <h4>{{ page_data.name }}</h4>
          <p>{{ format(page_data.created_at) }}</p>
        </div>
      </div>
    </b-card>
  </div>
</template>
  
  <script>
import { BCard, BButton, BRow, BCol, BImg } from "bootstrap-vue";
import formatDate from "@/composables/format-date/format-date";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BImg,
  },
  props: ["page_data"],
  data() {
    return {
      format: formatDate,
      ImagePlaceholder: require("@/assets/images/edu-year/subject.jpg"),
    };
  },
};
</script>
<style lang="scss">
@import "./_EdyYearInfoComponent.scss";
</style>
  