<template>
  <validation-observer ref="Form">
    <b-form>
      <b-modal id="bv-modal-add-subject" no-close-on-backdrop centered hide-footer>
        <template #modal-header>
          <div
            class="d-flex justify-content-between w-100 align-items-center"
          >
            <b-card-text class="mb-0">
              {{ $t("g.edu_year/add_subject") }}
            </b-card-text>
            <img
              :src="exitIcon"
              alt="Hide add subject"
              @click="handleClose"
              style="cursor: pointer;"
            />
          </div>
        </template>
        <b-form-group label-for="subjuct" :label="$t('g.subjuct')">
          <validation-provider
            #default="{ errors }"
            name="subjuct"
            rules="required"
          >
            <v-select
              v-model="form_data.subjects"
              :options="all_subjects"
              :state="errors.length > 0 ? false : null"
              multiple
              :reduce="(subject) => subject.id"
              class="edu-years"
              :placeholder="$t('g.subjuct')"
              label="name"
            />

            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>

        <b-row align-h="end" class="report-footer m-0 mt-5">
          <b-button
            variant="outline-primary"
            @click="handleClose"
            >{{ $t("g.cancel") }}
          </b-button>
          <b-button
            :disabled="is_loading"
            variant="primary"
            type="submit"
            @click.prevent="addNewSubject"
            >{{ $t("g.edu_year/send") }}
          </b-button>
        </b-row>
        <overlay-component :isLoading="is_loading" />
      </b-modal>
    </b-form>
  </validation-observer>
</template>
  
  <script>
import { required } from "@validations";
import OverlayComponent from "@/components/shared/OverlayComponent";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import {
  BModal,
  BButton,
  BCol,
  BForm,
  BFormSelect,
  BFormGroup,
  BRow,
  BCardText
} from "bootstrap-vue";
export default {
  components: {
    BModal,
    BButton,
    BForm,
    BFormSelect,
    BCol,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    BRow,
    BCardText,
    OverlayComponent
  },
  props:["is_deleted", "id"],
  data() {
    return {
      required: required,
      is_loading: true,
      all_subjects: null,
      form_data: {
        subjects: null,
      },
      exitIcon: require("@/assets/dev/exit_icon.svg"),
    };
  },
  created() {
    this.getSubjects();
  },
  watch:{
    "is_deleted":function(){
      this.getSubjects()
    }
  },
  methods: {
    handleClose(){
      this.$bvModal.hide('bv-modal-add-subject')
      this.form_data.subjects = null
    },
    getSubjects() {
      this.$http
        .get(`admin/subjects?edu_year=${this.id}`)
        .then((res) => {
          this.all_subjects = null
          this.all_subjects = res.data.data
          this.is_loading = false
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          console.log(err);
        });
    },


    addNewSubject() {
      this.$refs.Form.validate().then((success) => {
        if (success) {
          let formData = new FormData();
            for (const i in this.form_data.subjects) {
              formData.append(`subjects[${i}]`, this.form_data.subjects[i]);
            }
          this.$http
            .post(`admin/edu-years/${this.id}/add-subjects?_method=put`, formData)
            .then((res) => {
              this.$helpers.makeToast(
                "success",
                this.$t("g.send.successTitle"),
                this.$t("g.edu_year/edu_year_added_successfully")
                );
                this.$bvModal.hide('bv-modal-add-subject')
                this.$emit('get-subjects')
                this.getSubjects();
                setTimeout(() => {
                  this.form_data.subjects = null
                }, 1000)
            })
            .catch((err) => {
              this.$helpers.handleError(err);
              console.log(err);
            });
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.send.invalidInput"),
            this.$t("g.send.invalidInputBody")
          );
        }
      });
    },
  },
};
</script>
  
  <style lang="scss">
@import "./EduYearAddSubject";
</style>
  